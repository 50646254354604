<template>
  <div id="pending-output-products">
    <div class="pending-container">
      <div class="appointments-header">
        <div class="penden-title">
          Efetuar saída de produtos por atendimento <span>({{ count }} pendentes)</span>
        </div>
        <div class="appointments-right-text">
          <date-picker
            range
            class="period"
            v-model="period"
            format="DD [de] MMMM"
            :clearable="true"
          />
        </div>
      </div>

      <div class="row">
        <div v-for="patient in patients" :key="patient.id" class="col-3">
          <div class="card">
            <div class="card-details">
              <Avatar
                margin="0 10px 0 0"
                size="40px"
                :src="`${patient.picture}?x=${moment()}`"
              />
              <div class="text-details">
                <div class="name">{{ patient.name }}</div>
                <div class="date-status">
                  {{ patient.bills.length }} produtos
                  <!-- <span class="color-active">{{ moment(patient.created_at).format('DD/MM/YY') }}</span> -->
                  <!-- <span class="as">às</span> -->
                  <!-- <span class="color-active">{{ moment(billItem.created_at).format('HH:mm') }}</span> -->
                </div>
              </div>
            </div>
            <div class="action-wrapper">
              <div v-can="'EstDash2'" class="button-action" @click="editOutput(billItem)">
                Efetuar saída
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination-position">
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>

      <OutputByAttendanceModal
        :billItem="billItem"
        :getPendingAttendanceProcedures="getPendingProducts"
        :openBillToReceiveProductModal="openBillToReceiveProductModal"
      />

    </div>
  </div>
</template>

<script>
export default {
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    OutputByAttendanceModal: () => import('@/components/Warehouse/OutputByAttendanceModal'),
  },
  props: {
    openBillToReceiveProductModal: Function,
  },
  created () {
    this.getPendingProducts();
  },
  data() {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      period: [start, end],
      page: 1,
      limit: 0,
      count: 0,
      billItem: null,
      patients: [],
    }
  },
  methods: {
    parseStatus(status) {
      switch(status) {
        case 'ATTEND':
          return 'Compareceu';
        case 'SCHEDULED':
          return 'Sem situação';
        case 'CONFIRMED':
          return 'Confirmado';
        case 'FINISHED':
          return 'Finalizado';
        case 'MISSED':
          return 'Faltou';
        case 'CANCELLED':
          return 'Cancelado';
        default:
          return status;
      }
    },
    getStatusClass(status) {
      switch(status) {
        case 'ATTEND':
          return { 'status-icon': true, 'attend': true };
        case 'SCHEDULED':
          return { 'status-icon': true, 'schedule': true };
        case 'CONFIRMED':
          return { 'status-icon': true, 'confirmed': true };
        case 'FINISHED':
          return { 'status-icon': true, 'finished': true };
        case 'MISSED':
          return { 'status-icon': true, 'missed': true };
        case 'CANCELLED':
          return { 'status-icon': true, 'cancelled': true };
        default:
          return { };
      }
    },
    async editOutput(billItem) {
      this.billItem = billItem;
      await this.timer(300);
      this.$bvModal.show('output-by-attendance-modal')
    },
    getPendingProducts() {
      this.api.getPendingProducts(this.page, this.clinic.id, this.period[0], this.period[1])
      .then(res => {
        this.patients = res.data.data;
        this.limit = res.data.per_page;
        this.count = res.data.total;
      })
      .catch(err => this.$toast.error(err.message));
    },
    getPendingAppointmentProducts() {
      // this.api.getPendingAppointmentProducts(this.page, this.clinic.id, this.period[0], this.period[1])
      // .then(res => {
      //   this.appointments = res.data.data;
      //   this.limit = res.data.per_page;
      //   this.count = res.data.total;
      // })
      // .catch(err => this.$toast.error(err.message));
    },
    timer(interval) {
      return new Promise((resolve, reject) => setTimeout(resolve, interval))
    }
  },
  watch: {
    page: function() { this.getPendingProducts() },
    period: function() { this.getPendingProducts() },
  }
}
</script>
<style lang="scss">
#pending-output-products {
  .pending-container {
    width: 100%;
    padding: 20px 20px 0px 20px;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    text-align: left;
  }
  .appointments-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .penden-title {
    font-family: 'Red Hat Display';
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-blue);
    margin-bottom: 20px;

    span {
      color: var(--orange)
    }
  }
  .appointments-right-text {
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
  .card {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid var(--neutral-200);
  }
  .card-details {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .avatar {
    margin-right: 10px;
  }
  .text-details {
    max-width: 80%;
  }
  .name {
    font-size: 16px;
    font-weight: 400;
    color: var(--type-active);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .status {
    font-size: 12px;
    color: #8696AC;
    display: inline-flex;
    align-items: center;
  }
  .date-status {
    font-size: 12px;
    color: #8696AC;
  }
  .status-icon {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    border: none;
    margin: 0 4px 0 0;
    background-color: #8696AC;

    &.finished {
      background: #069763;
      border: 2px solid var(--states-success-soft-green);
    }

    &.attend {
      background: var(--blue-500);
      border: 2px solid var(--neutral-300);
    }

    &.schedule {
      background: #FFFFFF;
      border: 2px solid var(--neutral-400);
    }

    &.confirmed {
      background: #069763;
      border: 2px solid var(--states-success-soft-green);
    }

    &.missed {
      background: var(--states-error);
      border: 2px solid #FFD6D2;
    }

    &.cancelled {
      background: var(--orange);
      border: 2px solid var(--light-orange-200);
    }

  }
  .date {
    margin-left: 4px;
  }
  .as {
    margin: 0 4px;
  }
  .color-active {
    font-weight: 400;
    color: var(--type-active);
  }
  .action-wrapper {
    width: 100%;
    margin-top: 20px;
    text-align: right;
    .button-action {
      font-size: 14px;
      font-weight: 700;
      color: var(--blue-500);
      cursor: pointer;
    }
  }
  .pagination-position {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .mx-datepicker .mx-input-wrapper .mx-input {
    text-align: right !important;
  }
}
</style>
